import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

import { Dialog, CircularProgress } from "@mui/material/";

const useStyles = makeStyles((theme: Theme) => ({
	loading: {
		margin: "auto",
		backgroundColor: "transparent",
	},
	loadingDiv: {
		margin: "auto",
		backgroundColor: "transparent",
		width: 120,
		height: 120,
		boxShadow: "none",
	},
}));

const Loading = () => {
	const classes = useStyles();

	return (
		<Dialog open={true} PaperProps={{ className: classes.loadingDiv }}>
			<CircularProgress color="primary" size={80} className={classes.loading} />
		</Dialog>
	);
};

export default Loading;
