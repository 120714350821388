import { useState } from "react";

//dexie
import db from "../db/db";

//local
import { GET_USER } from "../pages/User/queries";
import { GraphQLClient } from "graphql-request";

const locationIdToAccess: any = { "/1/1/": "retail", "/1/2/": "finish", "/1/3/": "material" };

export default function useUserSync() {
	const [userSyncStatus, setUserSyncStatus] = useState("");

	const userSync = async (graphClient: GraphQLClient | null) => {
		console.log("userSync", graphClient);
		if (!graphClient) return false;
		return await graphClient.request(GET_USER).then(updateUser).catch(updateUserError);
	};

	const updateUser = async (d: any) => {
		let uafId = d?.authenticatedUsersData?.usersAppFunctions;
		let uafName: string[] = [];
		try {
			if (uafId) {
				for (const id of uafId) {
					uafName.push(locationIdToAccess[id]);
				}
			}
			let profile = sessionStorage.getItem("profile");
			let userInfo = { authenticatedUsersData: d.authenticatedUsersData, usersAppFunctions: uafName, profile: profile };
			localStorage.setItem("userInfo", JSON.stringify(userInfo));
			console.log("updateUser", d);
			if (!d.settings.nodes) return false;
			await db.settings.clear();
			return await db.settings
				.bulkAdd(
					d.settings.nodes,
					d.settings.nodes.map((n: any) => n.key)
				)
				.then(() => {
					console.log("settings saved");
					setUserSyncStatus("Success");
					return true;
				})
				.catch((e) => {
					console.log("update user error:", e);
					setUserSyncStatus("Sync Error: Settings not saved");
					return false;
				});
		} catch (e) {
			console.log("update user error:", e);
			return false;
		}
	};

	const updateUserError = async (d: any) => {
		setUserSyncStatus("Sync Error: " + d.message);
		return false;
	};

	return [userSync, userSyncStatus] as const;
}
