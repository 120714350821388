import { createContext, useState, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import { CircularProgress } from "@mui/material/";
//providers

import { AuthContext } from "../auth/AuthProvider";

const useStyles = makeStyles((theme: Theme) => ({
	loading: {
		margin: "auto",
		backgroundColor: "transparent",
	},
	loadingDiv: {
		margin: "auto",
		backgroundColor: "transparent",
		width: 120,
		height: 120,
		boxShadow: "none",
	},
}));

type ContextProps = {
	loading: number;
	setLoading: any;
};

export const LoadingContext = createContext<Partial<ContextProps>>({});

export const LoadingProvider = ({ children }: any) => {
	const classes = useStyles();

	const [loading, setLoadingVal] = useState(0);

	const handleClose = () => setLoading(false);

	const authContext = useContext(AuthContext);

	const setLoading = (val: boolean) => {
		setLoadingVal((prevState: number) => {
			let nv = val ? prevState++ : prevState--;
			return nv || 0;
		});
	};

	return (
		<LoadingContext.Provider
			value={{
				loading,
				setLoading,
			}}
		>
			{loading > 0 || !authContext.sessionChecked ? (
				<Dialog open={true} onClose={handleClose} PaperProps={{ className: classes.loadingDiv }}>
					<CircularProgress color="primary" size={80} className={classes.loading} />
				</Dialog>
			) : null}
			{children}
		</LoadingContext.Provider>
	);
};

export default LoadingProvider;
