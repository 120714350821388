import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { IconName, IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";

interface FAIconProps {
	icon: IconProp;
	type?: "far" | "fas";
	size?: SizeProp;
	fixedWidth?: boolean;
	color?: string;
}

export const Icon: React.FC<FAIconProps & FontAwesomeIconProps & React.ComponentProps<typeof FontAwesomeIcon>> = ({
	icon,
	type,
	size,
	fixedWidth,
	color,
	...otherProps
}) => {
	return (
		<FontAwesomeIcon
			size={size || "1x"}
			fixedWidth={fixedWidth === undefined ? true : false}
			color={color || "inherit"}
			icon={typeof icon === "string" ? [type || "fas", icon as IconName] : icon}
			{...otherProps}
		/>
	);
};
