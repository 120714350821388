import { createContext, useEffect, useState } from "react";
import sfAuth from "./SFAuth";
import useInterval from "./useInterval";

import Config from "../config";

const defaultAuthContext = {
	loadUserSession: () => Promise.resolve(),
	sessionChecked: false,
	loggedIn: false,
	currentUser: null,
	userIsInSilverFern: false,
	company: null,
	profiles: {
		mobileApps: null,
		inventory: null,
	},
	// eslint-disable-next-line
	redirectToLogin: () => {},
	// eslint-disable-next-line
	redirectToLogout: () => {},
	performCodeExchange: (code: string) => Promise.resolve(),
	error: null,
};

export const AuthContext = createContext<AuthContext>(defaultAuthContext);

interface AuthProviderProps {
	children: React.ReactNode;
}

const TOKEN_DURATION_SECONDS = Config.TOKEN_DURATION_SECONDS;

function AuthProvider({ children }: AuthProviderProps) {
	const [loggedIn, setLoggedIn] = useState(false);
	const [sessionChecked, setSessionChecked] = useState(false);
	const [currentUser, setCurrentUser] = useState<Maybe<SFUser>>(null);
	const [company, setCompany] = useState<Maybe<SFCompany>>(null);
	//const [mobileAppProfiles, setMobileAppProfiles] = useState<Maybe<MobileAppsProfile[]>>(null);
	const [inventoryProfiles, setInventoryProfiles] = useState<Maybe<InventoryProfile[]>>(null);
	const [authError, setAuthError] = useState<Maybe<string>>(null);

	/** Run initial session check */
	useEffect(() => {
		(async () => {
			try {
				let offlineAuthContext: OfflineAuthContext | null = JSON.parse(localStorage.getItem("offlineAuthContext")!);
				if (offlineAuthContext?.loggedIn) {
					setLoggedIn(offlineAuthContext.loggedIn);
					setSessionChecked(offlineAuthContext.sessionChecked);
					setCurrentUser(offlineAuthContext.currentUser);
					setCompany(offlineAuthContext.company);
					setInventoryProfiles(offlineAuthContext.profiles.inventory);
				} else {
					setLoggedIn(false);
				}
				//if no internet connection then don't check session
				//set user info from local storage
				if (!navigator.onLine) {
					return;
				}
				const hasSession = await sfAuth.checkSession();
				//console.log("hasSession", hasSession);
				if (hasSession !== 1) {
					// Utility route to remain on page even without a session; helpful for
					// checking cookies without getting redirected
					if (window.location.pathname.startsWith("/no-redirect") || window.location.pathname.startsWith("/forbidden")) {
						return;
					}
					// even if they can't get a valid session do not redirect to login if they have an offline auth context
					// this allows them to use the app if the internet connection is intermittent
					if (hasSession === 2 || !offlineAuthContext || !offlineAuthContext.loggedIn) {
						sfAuth.redirectToLogin();
					}
				} else {
					setLoggedIn(true);
					await fetchUserInfo();
				}
			} catch (e) {
				setLoggedIn(false);
			} finally {
				setSessionChecked(true);
			}
		})();

		window.addEventListener("online", () => sfAuth.checkSession());
		return () => {
			window.removeEventListener("online", () => sfAuth.checkSession());
		};
	}, []);

	/** Load user's auth session, updating the AuthProvider context with the result.
	 * @param redirect Whether to redirect to login if not logged in
	 * @returns `Promise<void>` for whether the user is logged in
	 */
	async function loadUserSession(redirect: boolean = true): Promise<void> {
		console.log("loadUserSession not in play");
		return;
	}

	/** Check session/renew access token periodically */
	useInterval(() => {
		(async () => {
			try {
				const hasSession = await sfAuth.checkSession();
				//if not authorized (not just can't connect or offline) then redirect to login
				if (hasSession === 2) {
					await sfAuth.redirectToLogin();
				}
			} catch (e) {
				console.log("Error periodically checking session: ", e);
				await sfAuth.redirectToLogin();
			}
		})();
	}, (TOKEN_DURATION_SECONDS * 1000) / 10);

	let userIsInSilverFern = false;
	const abbreviation = company?.abbreviation;
	if (abbreviation) {
		userIsInSilverFern = ["sfg", "sfd"].indexOf(company.abbreviation.toLowerCase()) > -1;
	}

	async function fetchUserInfo() {
		try {
			const user = await sfAuth.getCurrentUser();
			const company = await sfAuth.getCompany();
			//const mobileAppProfiles = await sfAuth.getMobileAppsProfiles();
			const inventoryAppProfiles = await sfAuth.getInventoryProfiles();

			setCurrentUser(user);
			setCompany(company);
			//setMobileAppProfiles(mobileAppProfiles);
			setInventoryProfiles(inventoryAppProfiles);
		} catch (e) {
			console.log("Error loading user info: ", e);
			setAuthError(`${e}`);
		}
	}

	/** Perform OAuth Authorization Code Flow code exchange with the provided `code` */
	async function performCodeExchange(code: string): Promise<void> {
		try {
			await sfAuth.exchangePKCECodeForTokens(code);
			await fetchUserInfo();
			setLoggedIn(true);
		} catch (e) {
			console.log("Error performing code exchange: ", e);
		}
	}

	const contextValue: AuthContext = {
		loadUserSession,
		loggedIn,
		sessionChecked,
		currentUser: currentUser,
		userIsInSilverFern,
		company: company,
		profiles: {
			mobileApps: null,
			inventory: inventoryProfiles,
		},
		redirectToLogin: sfAuth.redirectToLogin,
		redirectToLogout: sfAuth.redirectToLogout,
		performCodeExchange,
		error: authError,
	};

	let offlineContextValue: OfflineAuthContext = {
		loggedIn,
		sessionChecked,
		currentUser: currentUser,
		userIsInSilverFern,
		company: company,
		profiles: {
			inventory: inventoryProfiles,
		},
	};

	//save a copy of the current AuthContext to local storage if logged in and session hass benn checked
	if (offlineContextValue.loggedIn && offlineContextValue.sessionChecked) {
		localStorage.setItem("offlineAuthContext", JSON.stringify(offlineContextValue));
	}

	return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
