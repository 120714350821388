import { useEffect, useContext, useState, useRef } from "react";
import { Navigate } from "react-router-dom";
//ui
import Paper from "@mui/material/Paper";

//db
import db from "../../db/db";

//providers
import { AuthContext } from "../../auth/AuthProvider";
import { LoadingContext } from "../../providers/LoadingProvider";
import useUserSync from "../../hooks/useUserSync";
import { TranslationContext } from "../../providers/TranslationProvider";
import { GraphQLContext } from "../../providers/GraphqlProvider";

//local
import Config from "../../config";
import SFGLogo from "../../assets/SF-Logo-foronwhite.svg";
import SFGLogoDark from "../../assets/SFLogoDark.svg";
import SpacedGridContainer from "../../components/SpacedGridContainer";
import SpacedGridItem from "../../components/SpacedGridItem";
import { Alert } from "@mui/material";

const Home = (props: any) => {
	let userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");

	const authContext = useContext(AuthContext);
	const loadingContext: any = useContext(LoadingContext);
	const { graphClient } = useContext(GraphQLContext);
	const [lastPage, setLastPage] = useState("");
	const [userSyncd, setUserSyncd] = useState(userInfo?.authenticatedUsersData?.id ? true : false);
	const [userSync] = useUserSync();
	const userSyncing = useRef(0);

	const { getTranslation } = useContext(TranslationContext);

	const pageTitle = "Inventory";

	const pageButtons: any = [];

	const pageCallbacks = async () => {
		props.setTheme("main");
		props.setPageTitle(pageTitle);
		props.setPageButtons(pageButtons);
	};

	useEffect(() => {
		loadingContext.setLoading(true);
		console.log("userInfo", userInfo);
		//if user settings not set then wait on page try to get them when graphClient is set
		if (!userInfo || (userInfo && (Object.keys(userInfo).length === 0 || !userInfo.usersAppFunctions?.length))) {
			//TODO: temporary fallback in case no internet during switch get rid of this in future versions
			db.settings.toArray((s) => {
				let aud = s[0] as unknown as { authenticatedUsersData: any; usersAppFunctions: string[] };
				if (aud?.authenticatedUsersData?.id) {
					localStorage.setItem("userInfo", JSON.stringify(s[0]));
					setUserSyncd(true);
				}
			});

			pageCallbacks();
			loadingContext.setLoading(false);
		} else {
			let lp = localStorage.getItem("lastPage") || "";
			if (userInfo?.usersAppFunctions?.includes(lp)) {
				setLastPage(lp);
			} else {
				localStorage.setItem("lastPage", "");
				pageCallbacks();
				loadingContext.setLoading(false);
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		console.log("graphClient useEffect", graphClient);
		if (graphClient && !userSyncing.current) {
			userSyncing.current = 1;
			try {
				userSync(graphClient).then((res: any) => {
					console.log("userSync", res);
					setUserSyncd(true);
					loadingContext.setLoading(false);
					if (res === true) {
						setLastPage(localStorage.getItem("lastPage") || "");
						return;
					}
				});
			} catch (e) {
				console.log("userSync error", e);
				loadingContext.setLoading(false);
				setUserSyncd(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [graphClient]);

	if (loadingContext.loading || !authContext.sessionChecked) {
		return null;
	}

	if (!userSyncd) {
		return <Alert severity="info">{getTranslation("info-loading-user-info")}</Alert>;
	}

	return (
		<>
			{lastPage ? (
				<Navigate to={`/${lastPage}`} replace={true} />
			) : (
				<SpacedGridContainer>
					<SpacedGridItem maxCols={1} smMargin>
						<Paper sx={{ fontFamily: "Montserrat", textAlign: "center", padding: "1em" }}>
							<h1>{authContext.company?.name}</h1>
							<h4>Inventory App</h4>
						</Paper>
					</SpacedGridItem>
					<SpacedGridItem maxCols={1} smMargin>
						<Paper sx={{ textAlign: "center", padding: "1em" }}>
							<img alt="SFG Logo" src={props.themeType === "dark" ? SFGLogoDark : SFGLogo} style={{ width: "80%", maxWidth: "500px" }} />
						</Paper>
					</SpacedGridItem>
					<SpacedGridItem maxCols={1} smMargin>
						{getTranslation("general-release-version")}: {Config.releaseVersion}
					</SpacedGridItem>
				</SpacedGridContainer>
			)}
		</>
	);
};

export default Home;
