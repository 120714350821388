import { generateVerifierAndChallenge } from "./PKCEClient";
import Config from "../config";

const authUrl = Config.authUrl;
const sessionManagerUrl = Config.sessionManagerUrl;
const sfApiUrl = Config.sfApiUrl;
const appName = Config.appName;
const clientId = Config.clientId;
const tenantId = Config.tenantId;
const usePKCE = Config.usePKCE;

export class SFAuth {
	private static _instance: SFAuth;

	private currentAccessToken: Maybe<string> = null;
	private currentRefreshToken: Maybe<string> = null;

	// Singleton
	// eslint-disable-next-line
	private constructor() {}

	public static get Instance() {
		return this._instance || (this._instance = new this());
	}

	async checkSession(): Promise<number> {
		// Skip check if offline so app can be used in offline mode
		// should also add a call to CheckSession immediately when back online
		if (!navigator.onLine) {
			return 0;
		}
		if (usePKCE) {
			// PKCE session stores tokens in web storage and renews them via auth API as needed.
			return this.checkLocalSession();
		} else {
			// Default session stores tokens in cookies and makes request to the server that
			// set the cookies to check (and renew) session status.
			return this.checkDefaultSession();
		}
	}

	/** Return current tokens stored in memory if available. Else retrieve from
	 * localStorage. If not available, return null. */
	private getCurrentTokens(): Maybe<TokenSet> {
		let tokens: Maybe<TokenSet> = null;

		if (this.currentAccessToken && this.currentRefreshToken) {
			tokens = {
				accessToken: this.currentAccessToken,
				refreshToken: this.currentRefreshToken,
			};
		} else {
			const tokenStr = window.localStorage.getItem("appTokens");
			if (tokenStr) {
				tokens = JSON.parse(tokenStr);
			}
		}

		return tokens;
	}

	/** PKCE sessions are managed entirely on the front end in the browser.  */
	private async checkLocalSession(): Promise<number> {
		const tokens = this.getCurrentTokens();

		if (tokens === null) {
			return 2;
		}

		try {
			const freshTokens = await this.refreshPKCESessionIfNeeded(tokens);

			this.currentAccessToken = freshTokens.accessToken;
			this.currentRefreshToken = freshTokens.refreshToken;
			window.localStorage.setItem("appTokens", JSON.stringify(freshTokens));
			return 1;
		} catch (e) {
			console.log("Error parsing tokens: ", e);
			return 2;
		}
	}

	/** Returns a `Promise<boolean>` for whether or not valid tokens are stored (transparently renews
	 * access token if it is at/near expiry) */
	private async refreshPKCESessionIfNeeded(tokens: TokenSet): Promise<TokenSet> {
		let freshTokens = { ...tokens };

		// Refresh if access token expired
		if (this.isTokenNearExpiry(tokens.accessToken)) {
			freshTokens = await this.refreshToken(tokens.accessToken, tokens.refreshToken);
		}

		return freshTokens;
	}

	private async refreshToken(accessToken: string, refreshToken: string): Promise<JWTRefreshResponse> {
		const url = `${authUrl}/api/jwt/refresh`;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				token: accessToken,
				refreshToken,
			}),
		});

		if (!response.ok) {
			throw new Error(response.statusText);
		}

		const data = await response.json();
		return {
			accessToken: data.token,
			refreshToken: data.refreshToken,
		};
	}

	parseTokenPayload(accessToken: string) {
		const base64Url = accessToken.split(".")[1];
		const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
		const jsonPayload = decodeURIComponent(
			window
				.atob(base64)
				.split("")
				.map(function (c) {
					return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join("")
		);

		return JSON.parse(jsonPayload);
	}

	// Return true if access token is within `thresholdPercentage` of expiry relative to token lifetime
	// eg if thresholdPercentage is 20, and token lifetime is 3600 seconds, then if the token has less than
	// 720 seconds (20% of 3600 seconds) remaining, it is considered near expiry.
	isTokenNearExpiry(accessToken: string, thresholdPercentage: number = 20): boolean {
		const payload = this.parseTokenPayload(accessToken);
		// expiry and issued at timestamps in seconds
		const { exp, iat } = payload;

		const currentTime = Date.now() / 1000; // Convert to seconds
		const timeRemaining = exp - currentTime;
		const tokenLifetime = exp - iat; // Calculate token lifetime

		// Calculate the threshold time as a percentage of token lifetime
		const thresholdTime = (thresholdPercentage / 100) * tokenLifetime;

		// Check if the remaining time is less than or equal to the threshold time
		const nearExpiry = timeRemaining <= thresholdTime;

		return nearExpiry;
	}
	async exchangePKCECodeForTokens(code: string): Promise<void> {
		const verifier = window.localStorage.getItem("pkceVerifier");
		if (verifier === null) {
			throw new Error("No PKCE verifier found.");
		}

		const data = new URLSearchParams();
		data.append("client_id", clientId);
		data.append("code", code);
		data.append("code_verifier", verifier);
		data.append("grant_type", "authorization_code");
		// For PKCE, redirect is directly back to app origin
		data.append("redirect_uri", `${window.location.origin}/oauth-redirect`);

		const response = await fetch(`${authUrl}/oauth2/token`, {
			method: "POST",
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
			body: data,
		});

		if (response.status !== 200) {
			const text = await response.text();
			if (process.env.NODE_ENV === "development") {
				console.log("DEBUG Response: ", response);
				console.log("DEBUG Text: ", text);
			}
			const err = new Error("Error exchanging code for tokens");
			throw err;
		}

		const tokensResponse = await response.json();
		const tokens = {
			accessToken: tokensResponse.access_token,
			refreshToken: tokensResponse.refresh_token,
		};

		window.localStorage.setItem("appTokens", JSON.stringify(tokens));
		window.localStorage.removeItem("pkceVerifier");

		this.currentAccessToken = tokens.accessToken;
		return;
	}

	private async checkDefaultSession(): Promise<number> {
		try {
			const response = await fetch(`${sessionManagerUrl}/check-session?appName=${appName}`, {
				method: "HEAD",
				credentials: "include",
			});
			if (response.status === 200) {
				sessionStorage.setItem("lastSessionCheck", new Date().getTime().toString());
				return 1;
			}

			if (response.status === 401) {
				return 2;
			}

			const text = await response.text();
			console.log("Text: ", text);

			return 0;
		} catch (e) {
			console.log("Error checking session: ", e);
			return 0;
		}
	}

	async getCurrentUser(): Promise<Maybe<SFUser>> {
		let fetchOptions: RequestInit = {};
		if (usePKCE) {
			fetchOptions = {
				headers: {
					Authorization: `Bearer ${this.currentAccessToken}`,
				},
			};
		} else {
			fetchOptions = {
				credentials: "include",
			};
		}
		const response = await fetch(`${sfApiUrl}/me`, fetchOptions);

		if (response.status === 200) {
			const userInfo: SFUser = await response.json();
			return userInfo;
		}

		if (response.status === 401) {
			return null;
		}

		const err = new Error("Error checking session");
		throw err;
	}

	async getCompany(): Promise<Maybe<SFCompany>> {
		let fetchOptions: RequestInit = {};
		if (usePKCE) {
			fetchOptions = {
				headers: {
					Authorization: `Bearer ${this.currentAccessToken}`,
				},
			};
		} else {
			fetchOptions = {
				credentials: "include",
			};
		}
		const response = await fetch(`${sfApiUrl}/company`, fetchOptions);

		if (response.status === 200) {
			const company: SFCompany = await response.json();
			return company;
		}

		if (response.status === 401) {
			return null;
		}

		const err = new Error("Error fetching company.");
		throw err;
	}

	async getProfilesForProduct(productName: string): Promise<Maybe<SFAppProfile[]>> {
		const url = `${sfApiUrl}/profiles?productName=${productName}`;
		let fetchOptions: RequestInit = {};
		if (usePKCE) {
			fetchOptions = {
				headers: {
					Authorization: `Bearer ${this.currentAccessToken}`,
				},
			};
		} else {
			fetchOptions = {
				credentials: "include",
			};
		}
		const response = await fetch(url, fetchOptions);

		if (response.status === 200) {
			const profiles: SFAppProfile[] = await response.json();
			return profiles;
		}

		if (response.status === 401) {
			return null;
		}

		const err = new Error("Error checking session");
		throw err;
	}

	async getMobileAppsProfiles(): Promise<Maybe<MobileAppsProfile[]>> {
		const profiles = await this.getProfilesForProduct("mobile");
		if (!profiles) {
			return null;
		}

		return profiles as MobileAppsProfile[];
	}

	async getInventoryProfiles(): Promise<Maybe<InventoryProfile[]>> {
		const profiles = await this.getProfilesForProduct("inventory");
		if (!profiles) {
			return null;
		}

		return profiles as InventoryProfile[];
	}

	async redirectToLogin(onSuccessReturnToPath = true) {
		// Just came back from login attempt, don't redirect again
		if (window.location.pathname.startsWith("/oauth-redirect")) {
			return;
		}

		const queryparams: any = {
			client_id: clientId,
			// redirect_uri: `${redirectUri}${port ? `:${port}` : ''}/oauth-redirect`,
			redirect_uri: usePKCE ? `${window.location.origin}/oauth-redirect` : `${sessionManagerUrl}/oauth-redirect`,
			response_type: "code",
			scope: "openid profile email offline_access",
		};

		const authState = {
			// ** Optional **
			// By default, return to current path after authentication; else return to root
			returnTo: onSuccessReturnToPath ? window.location.pathname : "/",

			// ** Optional **
			// On dev, a port can be provided so that the redirect returns the user
			// back to the correct port after successful authentication.
			port: window.location.port,

			// Assigned below if PKCE is not used
			appName: usePKCE ? undefined : appName,
		};

		if (usePKCE) {
			const { verifier, challenge } = await generateVerifierAndChallenge();

			window.localStorage.setItem("pkceVerifier", verifier);

			queryparams.code_challenge_method = "S256";
			queryparams.code_challenge = challenge;
		} else {
			// ** Required for non-PKCE **
			// appName parameter through state for oauth redirect
			// (so we can use apps.silverfern.app and still pivot on domain)
			//authState.appName = appName;
		}

		queryparams.state = JSON.stringify(authState);

		const params = new URLSearchParams(queryparams);

		const qs = params.toString();
		window.location.href = `${authUrl}/oauth2/authorize?${qs}`;
	}

	redirectToLogout() {
		const params = new URLSearchParams({
			client_id: clientId,
			tenantId: tenantId,
			state: JSON.stringify({
				// ** Optional **
				// On dev, a port can be provided so that the redirect returns the user
				// back to the correct port after logging out.
				port: window.location.port,
			}),
		});
		const qs = params.toString();
		const logoutUrl = `${authUrl}/oauth2/logout?${qs}`;
		if (usePKCE) {
			window.localStorage.removeItem("appTokens");
		}

		window.location.href = logoutUrl;
	}
}

const sfAuth = SFAuth.Instance;

export default sfAuth;

// const verifier = 'Pq1ozabbNdEMjptJQS_0bauAtuViKlmS2sD9RwSRG5Y'

// Code verifier: gv8jP1FbL_D-WV33jU+OIhn64MJ+wLH5R4SynO9TIG4
// Code challenge: -pR6OfH79m_WNH8+iKat68U820FxFMGyDHtcqXF93XU
// Code verifier: XV2FKZxxv3p6sFCMBXAYPr3whV0BwhkP61LCpbFnukk
// Code challenge: DD6tuXU0myumpD4Gq61PH7F_7wjIxfRWNp5jnVf2/fw

// Exchange with data:
// client_id = 385ca222-ff34-4a58-9940-944e5dead573&
// client_secret = undefined&
// code = 3CtVFgZBc4me52julV7R119B10yWeC85n-Fdo3aBT9g&
// code_verifier = oipAhtv%2BL%2BsZDb12dawnKU8Ku8VPW8ZCQcZvvzJGLq8%3D&
// grant_type = authorization_code
// re

// Invalid code verifier:
// nbU8LUk16Knel9ixnlqbNDFYX713Tg%2FjGAkeB2dswAg%3D
