import { createContext, useState, useEffect } from "react";
import db from "../db/db";

import useTranslationsSync from "../hooks/useTranslationsSync";
import useInterval from "../hooks/useInterval";
import savedValue from "../helpers/savedValue";

interface TranslationContextProps {
	language: string;
	locale: string;
	setLanguage: (language: string) => void;
	getTranslation: (textKey: string, textDefault?: string, variables?: any) => string;
	translationsUpdated?: number;
	syncCompleted: boolean;
	setSyncCompleted: (completed: boolean) => void;
}

export const TranslationContext = createContext({} as unknown as TranslationContextProps);

interface TranslationProviderProps {
	children: React.ReactNode;
}

const TranslationProvider = ({ children }: TranslationProviderProps) => {
	const [language, setLanguage] = useState<string>(localStorage.getItem("language") || "en");
	const [syncCompleted, setSyncCompleted] = useState(false);
	const [translations, setTranslations] = useState<CurrentTranslations>({});
	const [translationsUpdated, setTranslationsUpdated] = useState(1);

	const getTranslation = (textKey: string, textDefault?: string, variables?: string[]) => {
		const keyToText = (key: string) => {
			let text = key.split("-").splice(1).join(" ");
			if (text) {
				text = text.charAt(0).toUpperCase() + text.slice(1);
			}
			return text || key;
		};

		let translation = translations[textKey.toLowerCase()] || textDefault || keyToText(textKey);

		if (variables) {
			for (let key in variables) {
				translation = translation.replace(`{${key}}`, variables[key]);
			}
		}

		return translation;
	};

	const contextValue: TranslationContextProps = {
		language: language,
		locale: language === "en" ? "en-US" : "es-MX",
		setLanguage: setLanguage,
		getTranslation: getTranslation,
		translationsUpdated: translationsUpdated,
		syncCompleted: syncCompleted,
		setSyncCompleted: setSyncCompleted,
	};

	useEffect(() => {
		if (syncCompleted) {
			localStorage.setItem("language", language);
			db.translations.toArray((data) => {
				let currentTranslations: CurrentTranslations = { translationsready: "ready" };
				data
					.filter((el) => el.language === language)
					.map((el) => {
						currentTranslations[el.key] = el.translationText;
						return el;
					});

				setTranslations(currentTranslations);
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [language, syncCompleted]);

	useEffect(() => {
		setTranslationsUpdated(Date.now());
	}, [translations]);

	return <TranslationContext.Provider value={contextValue}>{children}</TranslationContext.Provider>;
};

export default TranslationProvider;
