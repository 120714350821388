import { useState } from "react";

/* Status
	0 = checking
	1 = offline
	2 = online
	3 = online slow connection
	4 = checking off
*/

export default function useCheckInternet() {
	const [internetStatus, setInternetStatus] = useState(0);

	const checkInternet = async () => {
		//if (internetStatus < 2) setInternetStatus(0);
		if (localStorage.getItem("checkInternet") !== "true") {
			if (internetStatus !== 4) {
				setInternetStatus(4);
			}
			return;
		}
		const controller = new AbortController();
		setTimeout(() => controller.abort(), 10000);
		let newStatus = 0;
		try {
			let start = new Date().getTime();
			const online = await fetch("/1px.png?ref=" + new Date().getTime(), { signal: controller.signal });
			newStatus = online.status >= 200 && online.status < 300 ? 2 : 1; // online:offline
			if (newStatus === 2) {
				let duration = new Date().getTime() - start;
				newStatus = duration < 3000 ? 2 : 3;
			}
		} catch (err) {
			//console.log("controller.signal", controller.signal);
			newStatus = 1; // definitely offline
		} finally {
			if (newStatus !== internetStatus) {
				//console.log("setInternetStatus", newStatus);
				setInternetStatus(newStatus);
			}
		}
	};

	return { checkInternet, internetStatus, setInternetStatus } as const;
}
