const Config = {
	buildVersion: import.meta.env["VITE_BUILD_VERSION"]!,
	releaseVersion: import.meta.env["VITE_RELEASE_VERSION"]!,
	environment: import.meta.env["VITE_BUILD_ENV"]!,
	branch: import.meta.env["VITE_BUILD_BRANCH"]!,
	dexieVersion: 29,
	sentryDSN: "https://880547bf1fad4406a34fab9d2a43c777@o1137736.ingest.sentry.io/6190842",
	decimalPrecision: 5,
	endpoint: import.meta.env["VITE_GRAPHQL_ENDPOINT"]!,
	authUrl: import.meta.env["VITE_AUTH_URL"]!,
	sessionManagerUrl: import.meta.env["VITE_SESSION_MANAGER_URI"]!,
	sfApiUrl: import.meta.env["VITE_SF_API_URL"]!,
	appName: import.meta.env["VITE_APPLICATION_NAME"]!,
	clientId: import.meta.env["VITE_CLIENT_ID"]!,
	tenantId: import.meta.env["VITE_TENANT_ID"]!,
	usePKCE: import.meta.env["VITE_USE_PKCE"] === "true",
	TOKEN_DURATION_SECONDS: parseInt(import.meta.env["VITE_TOKEN_DURATION_SECONDS"]!) || 3600,
};

export default Config;
