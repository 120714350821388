// GraphQLProvider.js
import { createContext, useState, useEffect } from "react";
import { GraphQLClient } from "graphql-request";

import Config from "../config";
import { GraphQLClientRequestHeaders } from "graphql-request/build/esm/types";

interface GraphContext {
	graphClient: GraphQLClient | null;
}

export const GraphQLContext = createContext<GraphContext>({ graphClient: null });

interface GraphQLProviderProps {
	endpoint?: string;
	headers: any;
	profile: string;
	setClientSet: (clientSet: boolean) => void;
	children: React.ReactNode;
}

const GraphQLProvider = ({ endpoint, headers, profile, setClientSet, children }: GraphQLProviderProps) => {
	const getHeaders = (headers: {}) => {
		const tokenStr = window.localStorage.getItem("appTokens");
		const tokens: TokenSet = tokenStr ? JSON.parse(tokenStr) : {};
		let ret: GraphQLClientRequestHeaders = {
			...headers,
			profile: profile,
			"App-Version": Config.releaseVersion,
			"App-Install-Id": localStorage.getItem("App-Install-Id") || "",
		};
		if (tokens["accessToken"]) {
			ret.Authorization = `Bearer ${tokens["accessToken"]}`;
		}
		return ret;
	};

	const [graphQLClient, setGraphQLClient] = useState<GraphQLClient | null>(null);

	const contextValue: GraphContext = {
		graphClient: graphQLClient,
	};

	useEffect(() => {
		if (endpoint && headers && profile) {
			setGraphQLClient(
				new GraphQLClient(endpoint, {
					headers: () => getHeaders(headers),
					credentials: Config.usePKCE ? "omit" : "include",
					mode: "cors",
				})
			);
			setClientSet(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endpoint, headers, profile]);

	return <GraphQLContext.Provider value={contextValue}>{children}</GraphQLContext.Provider>;
};

export default GraphQLProvider;
