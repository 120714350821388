import { useContext } from "react";

//GraphQL
import { GraphQLContext } from "../providers/GraphqlProvider";

//dexie
import db from "../db/db";

//helpers
import savedValue from "../helpers/savedValue";

const GET_TRANSLATIONS = `
query {
  translations {
	nodes{
		key
		language
		translationText
	}
  }
}
`;

export default function useTranslationsSync() {
	const { graphClient } = useContext(GraphQLContext);

	const translationsSync = async () => {
		//console.log("translationsSync", graphClient);
		return graphClient?.request(GET_TRANSLATIONS).then(updateTranslations).catch(updateTranslationsError);
	};

	const updateTranslations = async (d: any) => {
		await db.translations.clear();

		try {
			db.translations
				.bulkPut(d.translations.nodes)
				.then(() => {
					window.dispatchEvent(new Event("languageChange"));
					savedValue("lastTranslationsSync", Date.now());
					return true;
				})
				.catch((e) => {
					console.log("Sync Error: Translations not saved");
					return false;
				});
		} catch (e) {
			console.log("Translations Sync Error:", e);
			return false;
		}
	};

	const updateTranslationsError = async (d: any) => {
		console.log("Sync Error: " + d.message);
	};

	return [translationsSync] as const;
}
