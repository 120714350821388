import { useEffect, useContext } from "react";
import Config from "../../config";

//material-ui
import { Button, Paper } from "@mui/material";

import { AuthContext } from "../../auth/AuthProvider";

import SFGLogo from "../../assets/SF-Logo-foronwhite.svg";
import SFGLogoDark from "../../assets/SFLogoDark.svg";

import { TranslationContext } from "../../providers/TranslationProvider";
import SpacedGridContainer from "../../components/SpacedGridContainer";
import SpacedGridItem from "../../components/SpacedGridItem";

const Forbidden = (props: any) => {
	const pageTitle = "Inventory";

	const pageButtons: any = [{}];

	const authContext = useContext(AuthContext);
	const { getTranslation } = useContext(TranslationContext);

	const pageCallbacks = async () => {
		props.setTheme("main");
		props.setPageTitle(pageTitle);
		props.setPageButtons(pageButtons);
	};

	useEffect(() => {
		pageCallbacks();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<SpacedGridContainer>
			<SpacedGridItem maxCols={1} smMargin>
				<h1>{getTranslation("error-not-authorized")}</h1>
				<h3>{getTranslation("info-contact-manager-for-assistance")}</h3>
			</SpacedGridItem>
			<SpacedGridItem maxCols={1} smMargin>
				<Button variant="contained" color="secondary" onClick={() => authContext.redirectToLogout()}>
					{getTranslation("action-try-login-again")}
				</Button>
			</SpacedGridItem>
			<SpacedGridItem maxCols={1} smMargin>
				<Paper style={{ padding: "1em", textAlign: "center" }}>
					<img alt="SFG Logo" src={props.themeType === "dark" ? SFGLogoDark : SFGLogo} style={{ width: "80%", maxWidth: "500px" }} />
				</Paper>
			</SpacedGridItem>
			<SpacedGridItem maxCols={1} smMargin>
				<p>Build Version: {Config.buildVersion}</p>
			</SpacedGridItem>
		</SpacedGridContainer>
	);
};
export default Forbidden;
