import { useContext } from "react";
import { AuthContext } from "../auth/AuthProvider";
import * as Sentry from "@sentry/react";
import SentryFallback from "./SentryFallback";

interface SentryErrorBoundryProps {
	showDialog: boolean;
	children: JSX.Element;
}

const SentryErrorBoundary = (props: SentryErrorBoundryProps) => {
	const { showDialog, children } = props;

	const authContext = useContext(AuthContext);

	return (
		<Sentry.ErrorBoundary
			fallback={SentryFallback}
			dialogOptions={{ user: { email: authContext.currentUser?.email, name: authContext.currentUser?.firstName + " " + authContext.currentUser?.lastName } }}
			showDialog={showDialog}
		>
			{children}
		</Sentry.ErrorBoundary>
	);
};

export default SentryErrorBoundary;
