import { createContext, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { themeCreator } from "../themes/pageThemes";

export const ThemeContext = createContext({
	themeName: "mainTheme",
	themeType: "light",
	themeSize: "14",
	setTheme: (themeName: string) => {},
	setThemeType: (type: string) => {},
	setThemeSize: (size: string) => {},
});

const CustomThemeProvider: React.FC = (props) => {
	// State to hold the selected theme name

	let userpref = localStorage.getItem("themeType") === "dark" ? "dark" : "light";
	let userprefSize = localStorage.getItem("themeSize") || "14";
	const [themeName, setThemeName] = useState("main");
	const [themeType, setThemeTypePref] = useState(userpref);
	const [themeSize, setThemeSizePref] = useState(userprefSize);
	const theme = themeCreator(themeName, themeType, themeSize);

	function setThemeType(type: string) {
		localStorage.setItem("themeType", type);
		setThemeTypePref(type);
	}

	function setThemeSize(size: string) {
		localStorage.setItem("themeSize", size);
		setThemeSizePref(size);
	}

	const contextValue = {
		themeName: themeName,
		setTheme: setThemeName,
		themeType: themeType,
		setThemeType: setThemeType,
		themeSize: themeSize,
		setThemeSize: setThemeSize,
	};

	return (
		<ThemeContext.Provider value={contextValue}>
			<ThemeProvider theme={theme}>{props.children}</ThemeProvider>
		</ThemeContext.Provider>
	);
};

export default CustomThemeProvider;
