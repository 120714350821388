import { StrictMode } from "react";
import ReactDOM from "react-dom";
import App from "./app";
import AuthProvider from "./auth/AuthProvider";
import "./index.css";

ReactDOM.render(
	<StrictMode>
		<AuthProvider>
			<App />
		</AuthProvider>
	</StrictMode>,
	document.getElementById("root")
);
