import { useEffect, useRef, useState, useContext } from "react";

//material-ui
import Container from "@mui/material/Container";

//local
import TopBar from "./TopBar";
import Router from "../router";
import NavDrawer from "./NavDrawer";

import { TranslationContext } from "../providers/TranslationProvider";
import { GraphQLContext } from "../providers/GraphqlProvider";
import useTranslationsSync from "../hooks/useTranslationsSync";

interface LayoutProps {
	updateRequired: boolean;
	setUpdateRequired: (v: boolean) => void;
	updateAvailable: boolean;
	applyUpdate: () => void;
	setClientSet: (v: boolean) => void;
	internetStatus: number;
}

const Layout = (props: LayoutProps) => {
	const { updateRequired, setUpdateRequired, updateAvailable, applyUpdate, setClientSet, internetStatus } = props;

	const [drawerOpen, setDrawerOpen] = useState(false);
	const [pageTitle, setPageTitle] = useState("");
	const [pageButtons, setPageButtons] = useState([]);

	const translationSyncInProgress = useRef(false);
	const { setSyncCompleted, syncCompleted } = useContext(TranslationContext);
	const { graphClient } = useContext(GraphQLContext);
	const [translationsSync] = useTranslationsSync();

	useEffect(() => {
		//console.log("Layout graphqlclient effect");
		//console.log(translationSyncInProgress.current, graphClient, syncCompleted);
		if (translationSyncInProgress.current === true) {
			//console.log("Layout", "translationSyncInProgress");
			return;
		}
		if (!graphClient) {
			//console.log("Layout", "No graphClient");
			return;
		}
		if (syncCompleted) {
			//console.log("Layout", "sync is completed");
			return;
		}

		//console.log("Layout", "start translationsSync");
		translationSyncInProgress.current = true;
		translationsSync().then(() => {
			//console.log("Layout", "translationSync Completed");
			setSyncCompleted(true);
			translationSyncInProgress.current = false;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [graphClient, syncCompleted]);

	return (
		<>
			<TopBar
				pageTitle={pageTitle}
				pageButtons={pageButtons}
				setDrawerOpen={setDrawerOpen}
				updateAvailable={updateAvailable}
				applyUpdate={applyUpdate}
				updateRequired={updateRequired}
				setUpdateRequired={setUpdateRequired}
				internetStatus={internetStatus}
			>
				<NavDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} updateAvailable={updateAvailable} applyUpdate={applyUpdate} />
			</TopBar>

			<Container
				maxWidth="xl"
				sx={{ padding: { xs: 0, md: 1 }, display: "flex", flexDirection: "column", justifyContent: "flex-start", flexGrow: 1, width: "100%" }}
			>
				<Router setPageTitle={setPageTitle} setPageButtons={setPageButtons} setClientSet={setClientSet} />
			</Container>
		</>
	);
};
export default Layout;
