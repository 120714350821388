import { useContext } from "react";
import { Link } from "react-router-dom";

import { TranslationContext } from "../providers/TranslationProvider";

//material-ui
import { Button, IconButton } from "@mui/material";

function PageButtons(props: any) {
	const { getTranslation } = useContext(TranslationContext);

	return (
		<>
			{props.pageButtons.map((pageBtn: any, i: number) =>
				pageBtn.disabled ? null : pageBtn.link ? (
					<Link
						key={pageBtn.key}
						style={{
							textDecoration: "none",
							width: "auto",
							minWidth: "25%",
							display: "flex",
							color: "#FFF",
						}}
						to={pageBtn.link}
						title={getTranslation(pageBtn.label)}
					>
						<IconButton color="inherit" size="large" sx={{ padding: { xs: "8px", sm: "16px" } }}>
							{pageBtn.icon}
						</IconButton>
					</Link>
				) : pageBtn.value ? (
					<Button
						style={{ whiteSpace: "nowrap", backgroundColor: "#FFFFFF" }}
						sx={{ padding: { xs: "8px", sm: "16px" } }}
						size="small"
						color="inherit"
						variant="text"
						onClick={(e) => {
							pageBtn.callback ? pageBtn.callback(e) : void 0;
						}}
						key={pageBtn.key}
						title={getTranslation(pageBtn.label)}
					>
						{pageBtn.value}
					</Button>
				) : pageBtn.icon ? (
					<IconButton
						sx={{ padding: { xs: "8px", sm: "16px" } }}
						color="inherit"
						onClick={(e) => {
							pageBtn.callback ? pageBtn.callback(e) : void 0;
						}}
						key={pageBtn.key}
						title={getTranslation(pageBtn.label)}
						size="large"
					>
						{pageBtn.icon} {pageBtn?.counts}
					</IconButton>
				) : null
			)}
		</>
	);
}

export default PageButtons;
