/*
Relevant spec for PKCE verifier and code challenge generation:
https://datatracker.ietf.org/doc/html/rfc7636#section-4.1
*/
export async function generateVerifierAndChallenge(): Promise<{ verifier: string; challenge: string }> {
  const verifier = generateCodeVerifier();
  const challenge = await generateCodeChallengeFromVerifier(verifier);
  return {
    verifier,
    challenge,
  };
}

function generateCodeVerifier() {
  function dec2hex(dec: number) {
    // Convert to hex, padding with 0 if only 1 hex char is the result
    return ('0' + dec.toString(16)).slice(-2);
  }

  var array = new Uint32Array(56 / 2);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec2hex).join('');
}

// Generate code challenge from code verifier
function sha256(plain: string) {
  // returns promise ArrayBuffer
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest('SHA-256', data);
}

function base64urlencode(a: ArrayBuffer) {
  var str = '';
  var bytes = new Uint8Array(a);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i]);
  }
  return window.btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

async function generateCodeChallengeFromVerifier(v: string) {
  var hashed = await sha256(v);
  var base64encoded = base64urlencode(hashed);
  return base64encoded;
}
