import { gql } from "graphql-request";

// pagination
// after endCursor
// first 20

export const GET_USER = gql`
	query {
		authenticatedUsersData {
			firstName
			lastName
			email
			usersAppFunctions
			id
			startingNodes {
				nodeId
				type
				id
				_Id
			}
		}
		settings(first: 2500) {
			nodes {
				key
				id
				value
			}
		}
	}
`;

export const GET_SETTINGS = gql`
	query {
		settings(first: 2500) {
			pageInfo {
				hasNextPage
				hasPreviousPage
				startCursor
				endCursor
			}
			nodes {
				key
				id
				value
			}
		}
	}
`;
