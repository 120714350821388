import { useState } from "react";
import Config from "../config";
import semverMajor from "semver/functions/major";
import semverMinor from "semver/functions/minor";
import semverPatch from "semver/functions/patch";
import semverValid from "semver/functions/valid";
import semverGt from "semver/functions/gt";

/* Status
	0 = not set
	1 = check failed
	2 = up to date
	3 = behind
	4 = outdated
	5 = obsolete
*/

export default function useCheckVersion() {
	const [working, setWorking] = useState(false);

	const getVersion = async () => {
		//console.log("getVersion");
		if (working) return;
		//if last successful check was less than 1 min ago, don't check again
		if (Number(localStorage.getItem("lastVersionCheck")) > Date.now() - 60 * 1000) return;
		setWorking(true);
		const controller = new AbortController();
		setTimeout(() => controller.abort(), 10000);
		try {
			const resp = await fetch("/version.json?ref=" + new Date().getTime(), { signal: controller.signal });
			if (!resp.ok) {
				return;
			}
			const json: any = await resp.json();
			localStorage.setItem("latestReleaseVersion", json.version);
			localStorage.setItem("minReleaseVersion", json.minVersion);
			localStorage.setItem("lastVersionCheck", new Date().getTime().toString());
		} catch (err) {
			console.log("controller.signal", controller.signal);
		} finally {
			setWorking(false);
		}
	};

	const checkVersion = () => {
		try {
			const latestVersion = semverValid(localStorage.getItem("latestReleaseVersion"));
			const minVersion = semverValid(localStorage.getItem("minReleaseVersion"));
			const currentVersion = semverValid(Config.releaseVersion);

			//if any of the versions are null, then skip checking
			if (latestVersion === null || minVersion === null || currentVersion === null) {
				return 0;
			}
			//if latest version is less than min version, then skip checking
			if (semverGt(minVersion, latestVersion)) {
				return 0;
			}

			const latestMajor = semverMajor(latestVersion);
			const minMajor = semverMajor(minVersion);
			const currentMajor = semverMajor(currentVersion);
			//if current major is less than latest major to min major, then it's obsolete
			if (latestMajor > currentMajor || minMajor > currentMajor) {
				return 5;
			}

			const latestMinor = semverMinor(latestVersion);
			const minMinor = semverMinor(minVersion);
			const currentMinor = semverMinor(currentVersion);
			//if current minor is less than latest minor, then it's obsolete
			if (currentMinor < minMinor) {
				return 5;
			}
			//if current minor is less than latest minor, then it's outdated
			if (currentMinor < latestMinor) {
				return 4;
			}

			const latestPatch = semverPatch(latestVersion);
			const minPatch = semverPatch(minVersion);
			const currentPatch = semverPatch(currentVersion);
			//if current patch is less than min patch, then it's obsolete
			if (currentPatch < minPatch) {
				return 5;
			}
			//if current patch is more than 3 patches behind latest patch, then it's outdated
			if (currentPatch + 3 < latestPatch) {
				return 4;
			}
			//if current patch is 1 patch behind latest patch, then it's behind
			if (currentPatch + 1 === latestPatch) {
				return 3;
			}
			//if current patch is latest patch, then it's up to date
			if (latestPatch === currentPatch) {
				return 2;
			}
			//could not determine status
			return 1;
		} catch (err) {
			return 1;
		}
	};

	return [checkVersion, getVersion] as const;
}
