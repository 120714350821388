import { Fragment } from "react";
import { Alert, Button } from "@mui/material";

interface SentryFallbackProps {
	error: any;
	componentStack: any;
	resetError: any;
}

const SentryFallback = ({ error, componentStack, resetError }: SentryFallbackProps) => {
	if (error?.name === "ChunkLoadError") {
		return (
			<Fragment>
				<Alert severity="info">
					<h3>
						Please{" "}
						<a href="/" style={{ textDecoration: "none" }}>
							<Button variant="contained" color="primary">
								Reload App
							</Button>
						</a>{" "}
						to complete update.
					</h3>
				</Alert>
			</Fragment>
		);
	}

	return (
		<Fragment>
			<Alert severity="error">
				<h3>You have encountered an error.</h3>
				<p>{error.toString()}</p>
			</Alert>
		</Fragment>
	);
};

export default SentryFallback;
