import { Theme, PaletteMode } from "@mui/material";
import { createTheme, darken, lighten } from "@mui/material/styles";
import merge from "lodash/merge";

var fontSize = 14;

declare module "@mui/material/styles" {
	interface Palette {
		cancel: Palette["primary"];
		plain: Palette["primary"];
		retail: Palette["primary"];
		finish: Palette["primary"];
		material: Palette["primary"];
		fulfill: Palette["primary"];
	}

	interface PaletteOptions {
		cancel?: PaletteOptions["primary"];
		plain?: PaletteOptions["primary"];
		retail?: PaletteOptions["primary"];
		finish?: PaletteOptions["primary"];
		material?: PaletteOptions["primary"];
		fulfill?: PaletteOptions["primary"];
	}
}

// Update the Button's color options to include an cancel option
declare module "@mui/material/Button" {
	interface ButtonPropsColorOverrides {
		cancel: true;
		plain: true;
		retail: true;
		finish: true;
		material: true;
		fulfill: true;
	}
}
declare module "@mui/material/IconButton" {
	interface IconButtonPropsColorOverrides {
		cancel: true;
		plain: true;
		retail: true;
		finish: true;
		material: true;
		fulfill: true;
	}
}

declare module "@mui/material/ToggleButton" {
	interface ToggleButtonPropsColorOverrides {
		cancel: true;
		plain: true;
		retail: true;
		finish: true;
		material: true;
		fulfill: true;
	}
}

declare module "@mui/material/TextField" {
	interface TextFieldPropsColorOverrides {
		cancel: true;
		plain: true;
		retail: true;
		finish: true;
		material: true;
		fulfill: true;
	}
}

declare module "@mui/material/Typography" {
	interface TypographyPropsColorOverrides {
		cancel: true;
		plain: true;
		retail: true;
		finish: true;
		material: true;
		fulfill: true;
	}
}

declare module "@mui/material/Chip" {
	interface ChipPropsColorOverrides {
		cancel: true;
		plain: true;
		retail: true;
		finish: true;
		material: true;
		fulfill: true;
	}
}

export function themeCreator(theme: string, type: string, defaultFontSize?: string | null): Theme {
	fontSize = Number(defaultFontSize) || fontSize;

	const mainColor = "#335936";
	const retailColor = darken("#58825b", 0.1);
	const finishColor = darken("#1893ae", 0.1);
	const materialColor = "#990000";
	const fulfillColor = "#881A1A";

	const typography = {
		fontSize: fontSize,
		fontWeightRegular: 400,
		fontWeightMedium: 400,
		fontWeightBold: 500,
	};

	const cancel = {
		main: "#dddddd",
		light: "#eeeeee",
		dark: "#aaaaaa",
		contrastText: "#333333",
	};
	const cancelDark = {
		main: "#444444",
		light: "#555555",
		dark: "#333333",
		contrastText: "#dddddd",
	};

	const plain = {
		main: "#555555",
		light: "#777777",
		dark: "#333333",
		contrastText: "#dddddd",
	};
	const plainDark = {
		main: "#dddddd",
		light: "#eeeeee",
		dark: "#aaaaaa",
		contrastText: "#333333",
	};

	const retail = {
		main: darken(retailColor, 0.2),
		light: darken(retailColor, 0.1),
		dark: darken(retailColor, 0.3),
		contrastText: "#dddddd",
	};

	const retailDark = {
		main: lighten(retailColor, 0.3),
		light: lighten(retailColor, 0.4),
		dark: darken(retailColor, 0.2),
		contrastText: "#dddddd",
	};

	const finish = {
		main: darken(finishColor, 0.2),
		light: darken(finishColor, 0.1),
		dark: darken(finishColor, 0.3),
		contrastText: "#dddddd",
	};

	const finishDark = {
		main: lighten(finishColor, 0.4),
		light: lighten(finishColor, 0.5),
		dark: darken(finishColor, 0.3),
		contrastText: "#dddddd",
	};

	const material = {
		main: darken(materialColor, 0.25),
		light: darken(materialColor, 0.15),
		dark: darken(materialColor, 0.35),
		contrastText: "#dddddd",
	};

	const materialDark = {
		main: lighten(materialColor, 0.3),
		light: lighten(materialColor, 0.4),
		dark: darken(materialColor, 0.2),
		contrastText: "#dddddd",
	};

	const fulfill = {
		main: fulfillColor,
		light: darken(fulfillColor, 0.15),
		dark: darken(fulfillColor, 0.35),
		contrastText: "#dddddd",
	};

	const fulfillDark = {
		main: fulfillColor,
		light: lighten(fulfillColor, 0.4),
		dark: darken(fulfillColor, 0.2),
		contrastText: "#dddddd",
	};

	const secondary = {
		main: "#b47a0c",
	};
	const secondaryDark = {
		main: "#CC7722",
	};

	const text = {
		primary: "#555555",
		secondary: "#777777",
	};

	const textDark = {
		primary: "#dddddd",
		secondary: "#aaaaaa",
	};

	const background = {
		default: "#ffffff",
		paper: "#f0f0f0",
	};

	const backgroundDark = {
		default: "#090909",
		paper: lighten("#090909", 0.16),
	};

	const components = {
		MuiAccordion: {
			styleOverrides: {
				root: {
					backgroundColor: type === "dark" ? lighten(backgroundDark.default, 0.08) : darken(background.paper, 0.1),
					backgroundImage: "none",
				},
			},
		},
		MuiAccordionSummary: {
			styleOverrides: {
				root: {
					color: type === "dark" ? lighten(textDark.primary, 0.2) : darken(text.primary, 0.2),
					backgroundColor: type === "dark" ? lighten(backgroundDark.default, 0.08) : darken(background.paper, 0.1),
					backgroundImage: "none",
				},
			},
		},
		MuiAppBar: {
			styleOverrides: {
				root: {
					backgroundImage: "none",
					"@media print": {
						display: "none",
					},
				},
			},
		},

		MuiDialog: {
			styleOverrides: {
				root: {
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					backgroundImage: "none",
				},
				container: {
					//width: "calc(100% - 16px)",
					width: "100%",
					maxWidth: "800px",
				},
				paper: {
					width: "100%",
					margin: 2,
					maxWidth: "unset",
					maxHeight: "calc(100% - 32px)",
					overflow: "auto",
					backgroundImage: "none",
				},
			},
		},

		MuiDialogTitle: {
			styleOverrides: {
				root: {
					"@media (max-width: 600px)": {
						padding: ".5em",
					},
				},
			},
		},
		MuiDialogContent: {
			styleOverrides: {
				root: {
					"@media (max-width: 600px)": {
						padding: ".5em",
					},
				},
			},
		},
		MuiDialogActions: {
			styleOverrides: {
				root: {
					"@media (max-width: 600px)": {
						padding: ".5em",
					},
				},
			},
		},

		MuiPaper: {
			styleOverrides: {
				root: {
					backgroundImage: "none",
				},
			},
		},
	};

	const baseTheme = {
		typography: typography,
		palette: {
			primary: {
				main: "#335936" /*"#69936C"*/,
			},
			secondary: secondary,
			cancel: cancel,
			plain: plain,
			retail: retail,
			finish: finish,
			material: material,
			fulfill: fulfill,
			background: background,
			text: text,
		},
		components: components,
	};

	const baseThemeDark = {
		typography: typography,
		palette: {
			mode: "dark" as PaletteMode,
			primary: {
				main: "#335936" /*"#69936C"*/,
			},
			secondary: secondaryDark,
			cancel: cancelDark,
			plain: plainDark,
			retail: retailDark,
			finish: finishDark,
			material: materialDark,
			fulfill: fulfillDark,
			background: backgroundDark,
			text: textDark,
		},
		components: components,
	};

	const mainTheme = createTheme(
		merge(baseTheme, {
			palette: {
				primary: {
					main: mainColor,
				},
			},
		})
	);

	const mainThemeDark = createTheme(
		merge(baseThemeDark, {
			palette: {
				primary: {
					main: darken(mainColor, 0.3),
				},
			},
		})
	);

	const retailTheme = createTheme(
		merge(baseTheme, {
			palette: {
				primary: {
					main: retailColor,
				},
			},
		})
	);

	const retailThemeDark = createTheme(
		merge(baseThemeDark, {
			palette: {
				primary: {
					main: darken(retailColor, 0.3),
				},
			},
		})
	);

	let finishTheme = createTheme(
		merge(baseTheme, {
			palette: {
				primary: {
					main: finishColor,
				},
			},
		})
	);

	const finishThemeDark = createTheme(
		merge(baseThemeDark, {
			palette: {
				primary: {
					main: darken(finishColor, 0.3),
				},
			},
		})
	);

	const materialTheme = createTheme(
		merge(baseTheme, {
			palette: {
				primary: {
					main: darken(materialColor, 0.3),
				},
			},
		})
	);

	const materialThemeDark = createTheme(
		merge(baseThemeDark, {
			palette: {
				primary: {
					main: darken(materialColor, 0.3),
				},
			},
		})
	);

	const fulfillTheme = createTheme(
		merge(baseTheme, {
			palette: {
				primary: {
					main: fulfillColor,
				},
			},
		})
	);

	const fulfillThemeDark = createTheme(
		merge(baseThemeDark, {
			palette: {
				primary: {
					main: darken(fulfillColor, 0.3),
				},
			},
		})
	);

	const themeMap: any = {
		main: mainTheme,
		retail: retailTheme,
		finish: finishTheme,
		material: materialTheme,
		fulfill: fulfillTheme,
	};

	const themeMapDark: any = {
		main: mainThemeDark,
		retail: retailThemeDark,
		finish: finishThemeDark,
		material: materialThemeDark,
		fulfill: fulfillThemeDark,
	};

	if (type === "dark") {
		if (themeMapDark[theme]) {
			return { ...themeMapDark[theme] };
		} else {
			return { ...themeMapDark["main"] };
		}
	}
	if (themeMap[theme]) {
		return { ...themeMap[theme] };
	} else {
		return { ...themeMap["main"] };
	}
}
