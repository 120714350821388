import { useEffect, useRef } from "react";

function useInterval(callback: any, delay: number | undefined) {
	const savedCallback = useRef(() => void 0);

	useEffect(() => {
		savedCallback.current = callback;
	});

	useEffect(() => {
		function tick() {
			savedCallback.current();
		}

		let id = setInterval(tick, delay);
		return () => clearInterval(id);
	}, [delay]);
}

export default useInterval;
