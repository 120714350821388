import { useContext, useState, useLayoutEffect, useEffect } from "react";
import { useRegisterSW } from "virtual:pwa-register/react";
import * as Sentry from "@sentry/react";
import SentryErrorBoundary from "./helpers/SentryErrorBoundary";

import { v4 as uuidv4 } from "uuid";

import GraphQLProvider from "./providers/GraphqlProvider";
import TranslationProvider from "./providers/TranslationProvider";

//Theme - material-ui
import CustomThemeProvider from "./providers/CustomThemeProvider";
import { CssBaseline } from "@mui/material";

//icons
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faArrowAltUp,
	faArrowLeft,
	faBackspace,
	faBan,
	faBarcodeRead,
	faBars,
	faBoxesStacked,
	faCalculatorAlt,
	faCalculatorSimple,
	faCamera,
	faCaretSquareDown,
	faCaretSquareUp,
	faCheckCircle,
	faChevronLeft,
	faChevronRight,
	faClipboardListCheck,
	faClock,
	faCloudCheck,
	faCloudQuestion,
	faCloudXmark,
	faCog,
	faDivide,
	faEdit,
	faEquals,
	faExclamationTriangle,
	faExpand,
	faFile,
	faGlobe,
	faHome,
	faHourglass,
	faList,
	faMinus,
	faPlus,
	faSearch,
	faSort,
	faSpinnerThird,
	faStore,
	faSync,
	faTh,
	faTimes,
	faTimesCircle,
	faTrash,
	faWarehouseAlt,
} from "@fortawesome/pro-solid-svg-icons";

//providers
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";
import { AuthContext } from "./auth/AuthProvider";
import { LoadingProvider } from "./providers/LoadingProvider";
import useCheckInternet from "./hooks/useCheckInternet";
import useCheckVersion from "./hooks/useCheckVersion";
import useInterval from "./hooks/useInterval";
import { BrowserRouter, createRoutesFromChildren, matchRoutes, Route, Routes, useLocation, useNavigationType } from "react-router-dom";
import { createBrowserHistory } from "history";
import Layout from "./layout/Layout";
import OAuthRedirect from "./pages/OAuthRedirect";

import Config from "./config";
import produce from "immer";

library.add(
	faArrowAltUp,
	faArrowLeft,
	faBackspace,
	faBan,
	faBarcodeRead,
	faBars,
	faBoxesStacked,
	faCalculatorAlt,
	faCalculatorSimple,
	faCamera,
	faCaretSquareDown,
	faCaretSquareUp,
	faCheckCircle,
	faChevronLeft,
	faChevronRight,
	faClipboardListCheck,
	faClock,
	faCloudCheck,
	faCloudQuestion,
	faCloudXmark,
	faCog,
	faDivide,
	faEdit,
	faEquals,
	faExclamationTriangle,
	faExpand,
	faFile,
	faGlobe,
	faHome,
	faHourglass,
	faList,
	faMinus,
	faPlus,
	faSearch,
	faSpinnerThird,
	faSort,
	faStore,
	faSync,
	faTh,
	faTimes,
	faTimesCircle,
	faTrash,
	faWarehouseAlt
);

//only set this if needed, should be using profiles from authContext
const endpoint = Config.endpoint;

const App = () => {
	const authContext = useContext(AuthContext);

	if (!Sentry.getClient()) {
		Sentry.init({
			dsn: Config.sentryDSN,
			release: Config.buildVersion,
			environment: Config.environment,
			tracesSampleRate: Config.environment === "production" ? 0.1 : 1.0,
			replaysSessionSampleRate: Config.environment === "production" ? 0.01 : 0.1,
			replaysOnErrorSampleRate: 1,
			sendDefaultPii: true,
			transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
			integrations: [
				Sentry.reactRouterV6BrowserTracingIntegration({
					useEffect: useEffect,
					useLocation,
					useNavigationType,
					createRoutesFromChildren,
					matchRoutes,
				}),
				Sentry.replayIntegration({
					maskAllText: false,
					blockAllMedia: false,
					maskAllInputs: false,
					networkDetailAllowUrls: [window.location.origin, /.*silverfern\.(app|red|local)/],
				}),
			],
		});
	}

	Sentry.setUser({
		email: authContext.currentUser?.email || "notAvailable",
		username: authContext.currentUser?.firstName + " " + authContext.currentUser?.lastName,
	});

	const [clientSet, setClientSet] = useState<boolean | null>(null);
	const [profile, setProfile] = useState({ name: "", endpoint: "" });
	const [updateRequired, setUpdateRequired] = useState(false);
	const { checkInternet, internetStatus } = useCheckInternet();
	const [checkVersion, getVersion] = useCheckVersion();

	if (localStorage.getItem("App-Install-Id") === null) {
		localStorage.setItem("App-Install-Id", uuidv4());
	}

	useInterval(
		() => {
			checkInternet();
			doCheckVersion();
		},
		30 * 1000 //every 30 seconds
	);

	useInterval(
		() => {
			getVersion();
		},
		15 * 60 * 1000 //every 15 minutes
	);

	const doCheckVersion = () => {
		let versionStatus = checkVersion();
		//console.log("version status: " + versionStatus);
		if (versionStatus > 3) {
			//if last update prompt was less then 1 hour ago, don't prompt again
			//console.log("last version prompt: " + Number(localStorage.getItem("lastVersionPrompt")));
			//console.log("now: " + Date.now());
			if (Number(localStorage.getItem("lastVersionPrompt")) > Date.now() - 60 * 60 * 1000) return;
			//force serviceworker to check for update
			navigator.serviceWorker.getRegistration().then((registration) => {
				registration?.update();
			});
			setUpdateRequired(true);
			localStorage.setItem("lastVersionPrompt", new Date().getTime().toString());
		}
	};

	const applyUpdate = () => {
		if (navigator.onLine) {
			setNeedRefresh(false);
			updateServiceWorker(true);
		}
	};

	async function tryPersistWithoutPromtingUser() {
		//skipping for now on mac and ios
		const userAgent = window.navigator.userAgent;
		const isMac = userAgent.indexOf("Macintosh") !== -1;
		const isIOS = userAgent.match(/(iPad|iPhone|iPod)/);
		if (isMac || isIOS) {
			return "never";
		}
		if (!navigator.storage || !navigator.storage.persisted) {
			return "never";
		}
		let persisted = await navigator.storage.persisted();
		if (persisted) {
			return "persisted";
		}
		if (!navigator.permissions || !navigator.permissions.query) {
			return "prompt"; // It MAY be successful to prompt. Don't know.
		}
		const permission = await navigator.permissions.query({
			name: "persistent-storage",
		});
		if (permission.state === "granted") {
			persisted = await navigator.storage.persist();
			if (persisted) {
				return "persisted";
			} else {
				throw new Error("Failed to persist");
			}
		}
		if (permission.state === "prompt") {
			return "prompt";
		}
		return "never";
	}

	const {
		//offlineReady: [offlineReady, setOfflineReady],
		needRefresh: [needRefresh, setNeedRefresh],
		updateServiceWorker,
	} = useRegisterSW({
		onRegistered(r) {
			// eslint-disable-next-line prefer-template
			"SW Registered: " + r;
			r &&
				setInterval(async () => {
					try {
						const ping = !r.installing && navigator && "connection" in navigator ? navigator.onLine : true;
						console.log("pinging service-worker.js", ping);
						const response = ping
							? await fetch("/service-worker.js", {
									cache: "no-store",
							  })
							: undefined;
						console.log("fetch service-worker.js", response);
						response && response.status === 200 && (await r.update());
					} catch (e) {
						console.log("cannot ping/update service-worker.js", e);
					}
				}, 1000 * 60 * 5 /* 5 minutes */);
		},
		onRegisterError(error) {
			console.log("SW registration error", error);
		},
	});

	useLayoutEffect(() => {
		checkInternet();
		getVersion();
		tryPersistWithoutPromtingUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//console.log("authContext", authContext);

	useEffect(() => {
		//console.log("clientSet: " + clientSet);
		//console.log("authContext.profiles?.inventory: " + authContext.profiles?.inventory);
		if (clientSet || authContext.profiles?.inventory == null) return;
		let savedProfile = sessionStorage.getItem("profile")?.toLowerCase().trim();
		let currentProfile: InventoryProfile | undefined;
		//if there is a saved profile, see if there is a matching profile in the list
		if (savedProfile) {
			currentProfile = authContext.profiles?.inventory?.find((p) => p.name === savedProfile);
		}
		//if there is no saved profile, use the default profile and check if it is in the list
		if (!currentProfile) {
			currentProfile = authContext.profiles?.inventory?.find((p) => p.name === (Config.environment === "production" ? "default" : "dev"));
		}
		sessionStorage.setItem("profile", currentProfile?.name || "");
		Sentry.setTag("profile", currentProfile?.name || "unknown");

		setProfile(
			produce((draft) => {
				draft.name = currentProfile?.name || "unknown";
				draft.endpoint = currentProfile?.graphUrl || "";
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clientSet, authContext.profiles?.inventory]);

	/*
	if (!profile.name) {
		return <>Loading...</>;
	}
	*/

	return (
		<GraphQLProvider endpoint={endpoint || profile.endpoint} profile={profile.name} headers={{}} setClientSet={setClientSet}>
			<CustomThemeProvider>
				<TranslationProvider>
					<LoadingProvider>
						<SnackbarProvider
							dense
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "center",
							}}
						>
							<ConfirmProvider>
								<CssBaseline />
								<SentryErrorBoundary showDialog={false}>
									<BrowserRouter>
										<Routes>
											<Route path="/oauth-redirect" element={<OAuthRedirect />} />
											<Route
												path="*"
												element={
													<Layout
														updateAvailable={needRefresh}
														applyUpdate={applyUpdate}
														setClientSet={setClientSet}
														internetStatus={internetStatus}
														updateRequired={updateRequired}
														setUpdateRequired={setUpdateRequired}
													/>
												}
											/>
										</Routes>
									</BrowserRouter>
								</SentryErrorBoundary>
							</ConfirmProvider>
						</SnackbarProvider>
					</LoadingProvider>
				</TranslationProvider>
			</CustomThemeProvider>
		</GraphQLProvider>
	);
};

export default App;
