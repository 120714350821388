import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

//material-ui
import { makeStyles, useTheme } from "@mui/styles";
import { Drawer, List, Divider, ListItemButton, ListItemIcon, ListItemText, IconButton, Badge, Select, MenuItem } from "@mui/material";

//hooks
import { useSnacks } from "../providers/SnackProvider";

import { TranslationContext } from "../providers/TranslationProvider";

//components
import { Icon } from "../components/Icons";

export const drawerWidth = "100%";
export const drawerWidthMax = 360;

const useStyles = makeStyles((theme?: any) => ({
	root: {
		display: "flex",
	},
	drawer: {
		width: drawerWidth,
		maxWidth: drawerWidthMax,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
		maxWidth: drawerWidthMax,
	},
	drawerHeader: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: "flex-end",
	},
	navLink: {
		textDecoration: "none",
		color: "inherit",
	},
	update: {
		backgroundColor: theme.palette.warning.main,
		color: "white",
	},
}));

interface NavDrawerProps {
	drawerOpen: boolean;
	setDrawerOpen: (v: boolean) => void;
	updateAvailable: boolean;
	applyUpdate: () => void;
}

function NavDrawer(props: NavDrawerProps) {
	const { drawerOpen, setDrawerOpen, updateAvailable, applyUpdate } = props;
	const { language, setLanguage, getTranslation } = useContext(TranslationContext);

	const [usersAppFunctions, setUsersAppFunctions] = useState<string[]>([]);

	const [openSnack] = useSnacks();

	const classes = useStyles();
	const theme: any = useTheme();

	var doc = window.document;
	var docEl = doc.documentElement;

	//navigation menu
	var navMenu = [
		{
			name: getTranslation("function-retail"),
			access: "retail",
			path: "/retail",
			icon: <Icon icon="store" size="lg" />,
		},
		{
			name: getTranslation("function-finish"),
			access: "finish",
			path: "/finish",
			icon: <Icon icon="warehouse-alt" size="lg" />,
		},
		{
			name: getTranslation("function-material"),
			access: "material",
			path: "/material",
			icon: <Icon icon="boxes-stacked" size="lg" />,
		},
	];

	const toggleFullScreen = () => {
		var requestFullScreen = docEl.requestFullscreen;
		var cancelFullScreen = doc.exitFullscreen;

		if (!doc.fullscreenElement) {
			requestFullScreen.call(docEl);
		} else {
			cancelFullScreen.call(doc);
		}
	};

	useEffect(() => {
		//console.log("set language", language);
		localStorage.setItem("language", language);
		window.dispatchEvent(new Event("languageChange"));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [language]);

	const doApplyUpdate = () => {
		if (navigator.onLine) {
			localStorage.setItem("lastVersionCheck", "0");
			applyUpdate();
		} else {
			openSnack(getTranslation("warning-internet-required-to-update"), "warning");
		}
	};

	//Load function access from settings
	useEffect(() => {
		//default to retail and finish access temporarily while switching from
		// getting this info from firebase to graphql
		setUsersAppFunctions(["retail", "finish", "material"]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Drawer
			className={classes.drawer}
			variant="persistent"
			anchor="left"
			open={drawerOpen}
			classes={{
				paper: classes.drawerPaper,
			}}
		>
			<div className={classes.drawerHeader}>
				<IconButton onClick={() => setDrawerOpen(false)} size="large">
					{theme.direction === "ltr" ? <Icon icon="chevron-left" /> : <Icon icon="chevron-right" />}
				</IconButton>
			</div>

			<Divider />
			{updateAvailable ? (
				<>
					<List className={classes.update}>
						<ListItemButton key="Update" onClick={doApplyUpdate} color="primary">
							<ListItemIcon>
								<Badge color="error" variant="dot" overlap="rectangular">
									<Icon icon="arrow-alt-up" size="lg" />
								</Badge>
							</ListItemIcon>
							<ListItemText primary={getTranslation("action-update-app", "Update App")} />
						</ListItemButton>
					</List>
					<Divider />
				</>
			) : null}

			<List>
				{navMenu.map(function (item, index) {
					//JUST SHOW ALL ITEMS UTILL ACCESS ASSIGNMENT VIA API IS READY
					if (1 || item.access === "" || (usersAppFunctions && usersAppFunctions.includes(item.access))) {
						return (
							<Link to={item.path} key={index} className={classes.navLink} onClick={() => setDrawerOpen(false)}>
								<ListItemButton key={index}>
									<ListItemIcon>{item.icon}</ListItemIcon>
									<ListItemText primary={item.name} />
								</ListItemButton>
							</Link>
						);
					} else {
						return null;
					}
				})}
			</List>
			<Divider />
			<List>
				<Link to={"/settings"} className={classes.navLink} onClick={() => setDrawerOpen(false)}>
					<ListItemButton>
						<ListItemIcon>
							<Icon icon="cog" size="lg" />
						</ListItemIcon>
						<ListItemText primary={getTranslation("general-settings")} />
					</ListItemButton>
				</Link>
				<List>
					<Link to={"/documentation"} className={classes.navLink} onClick={() => setDrawerOpen(false)}>
						<ListItemButton>
							<ListItemIcon>
								<Icon icon="file" size="lg" />
							</ListItemIcon>
							<ListItemText primary={getTranslation("general-documentation")} />
						</ListItemButton>
					</Link>
				</List>
				<List>
					<ListItemButton key="Language">
						<ListItemIcon>
							<Icon icon="globe" size="lg" />
						</ListItemIcon>

						<Select
							variant="standard"
							value={language}
							fullWidth={true}
							onChange={(event) => {
								//console.log(event.target.value);
								setLanguage(event.target.value as string);
							}}
						>
							<MenuItem key="en" value="en">
								English
							</MenuItem>
							<MenuItem key="es" value="es">
								Spanish
							</MenuItem>
						</Select>
					</ListItemButton>
				</List>
			</List>
			{docEl && "requestFullscreen" in docEl ? (
				<List>
					<ListItemButton key="Settings" onClick={toggleFullScreen}>
						<ListItemIcon>
							<Icon icon="expand" size="lg" />
						</ListItemIcon>
						<ListItemText primary={getTranslation("action-full-screen")} />
					</ListItemButton>
				</List>
			) : null}
		</Drawer>
	);
}

export default NavDrawer;
